import React from "react";
// import AppIcons from "../../assets/images/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './TestimonialsList.scss'



const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3500,
  zoom: true,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};


function TestimonialsList() {


  const TestimonialsListing = [
    {
      // testimg: AppIcons.test1,
      testdiscription: <p>My experience working with Propshop to purchase a new home was exceptional. They were professional, knowledgeable, and highly responsive to all of my needs. I highly recommend Propshop to anyone looking to buy or sell a home. They are a team of true professionals dedicated to providing exceptional service.</p>,
      names: <b>Ali Siddiqui</b>,
      
    },
    {
      // testimg: AppIcons.test2,
      testdiscription: <p>Propshop's team provided excellent customer service, answering my questions and providing regular updates on my purchase. I felt like they were invested in helping me find the perfect investment.</p>,
      names: <b>Latifa Al Dhaheri</b>,
      
    },
    {
      // testimg: AppIcons.test3,
      testdiscription: <p>Propshop impressed me with their attention to detail and understanding of my needs. They provided options that fit my criteria perfectly and their expertise made the buying process easy.</p>,
      names: <b>Rashid Al Shamsi</b>,
      
    },
    {
      // testimg: AppIcons.test4,
      testdiscription: <p>Having conducted several transactions in real estate over the years, PropShop by far outperformed any other brokerage we have worked with in terms of efficiency and professionalism. It was a hassle-free experience.</p>,
      names: <b>Christian Thordal </b>,
      // place: <p>Investor from Kjøller</p>,
    },

  ];




  return (
    <>
      <Slider {...settings}>

        {TestimonialsListing.map((element, key) => (
          <>
            <div key={key} className="each-testimonials-items f-wrp">
              {/* <div className="test-prof-img f-wrp">
                <img src={element.testimg} alt="testimonial" />
              </div> */}
              <div className="test-con-wrp f-wrp">
                {element.testdiscription}
                <b>{element.names}</b>
                {/* <p>{element.place}</p> */}
              </div>
            </div>
          </>
        ))}
      </Slider>
    </>
  );
}

export default TestimonialsList;