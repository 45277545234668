import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import AboutUs from './pages/public/About';
import Listing from './pages/public/Listing';

import SamplePage from './Features/SamplePage';
import Layout from './Layout';
import PropertyDetails from './pages/public/Listing/PropertyDetails';
import MatchMaking from './pages/public/MatchMaking';
// import Home from './Components/Main/home';
import FractionalPage from './Components/FractionalPage';
import TraditionalPage from './Components/TraditionalPage';

const AppRoutes = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" key={'home'} isHome={true} element={<LandingPage isHome={true} />} />
                {/* <Route path="/home" element={<Home />} /> */}
                <Route path="/fractional" element={<FractionalPage />} />
                <Route path="/traditional" element={<TraditionalPage />} />

                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/exclusive-listings" element={<Listing />} />
                <Route path="/property-detail/:id" element={<PropertyDetails />} />
                <Route path="/match-making" element={<MatchMaking />} />







                <Route path="/contact-us" element={<SamplePage />} />
            </Routes>
        </Layout>
    )
}

export default AppRoutes;