import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


// import required modules
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";

export default function ListThumbSlider({ images = [], thumbnails = [] }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const sliderImages = images.filter((img)=> {
        const isThumbnail = thumbnails.find((i)=>i.fileKey === img.fileKey);
        return isThumbnail !== undefined;
    });
    const sliderImagesWithThumbnails = thumbnails.concat(sliderImages);
    return (
        <>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                className="mySwiper2 gallery-slider"
            >
                {sliderImagesWithThumbnails.map((img) => <SwiperSlide>
                    <img src={img} alt="property preview" />
                </SwiperSlide>)}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={2}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper gallery-slider-thumb"
            >
                {sliderImages.map((img) => <SwiperSlide>
                    <img src={img} alt="property thumb" />
                </SwiperSlide>)}
            </Swiper>
        </>
    );
}
