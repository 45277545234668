import React from "react";
// import AppIcons from "../../assets/images/icons";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Transparency from '../../assets/images/icons/Transparency.svg';
import Bestvalue from '../../assets/images/icons/Bestvalue.svg';
import Easyclosings from '../../assets/images/icons/Easyclosings.svg';
import network from '../../assets/images/icons/network.svg';

import './OurValues.scss'



function OurValues() {

  const valueContent = [
    {
      className: 'col-lg-3 col-md-6 col-sm-6 col-xs-12',
      title: 'Transparency',
      icon: Transparency,
    },
    {
      className: 'col-lg-3 col-md-6 col-sm-6 col-xs-12',
      title: 'Best value',
      icon: Bestvalue,
    },
    {
      className: 'col-lg-3 col-md-6 col-sm-6 col-xs-12',
      title: 'Hassle Free',
      icon: Easyclosings,
    },
    {
      className: 'col-lg-3 col-md-6 col-sm-6 col-xs-12',
      title: 'Global Network',
      icon: network,
    },

  ];

  return (
    <>
      <div className="value-grid-wrp f-wrp">
        <div className="row">
          {valueContent.map((element, key) => (
            <div className={`${element.className} each-blk-sec`} key={key}>
              <div className="each-abt-blk f-wrp">
                <div className='icon-blk'>
                  {element.icon && <span><img src={element.icon} alt="our-value icon" /></span>}
                </div>
                <div className='cont-blk'>
                  <label>{element.title}</label>
                  <p>{element.discription}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default OurValues;