import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AppIcons from '../../assets/images/icons';
import mail from '../../assets/images/icons/email.svg';



import './ContactPopup.scss'
// import { Link } from 'react-router-dom';
import { showSnackbar } from '../Notification/Snackbar';
import { postQuery } from '../../services/api/enquires';


function ContactPopup(props) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  /**
 * sets the form data 
 * @param {string} name the name of the field
 * @param {string} value  the value of the field
 */
  const setFormValue = (name, value) => {
    const formDataAs = { ...formData };
    setFormData({ ...formDataAs, ...{ [name]: value } });
  }

  /**
   * Validates the form and returns true if valid
   * @returns boolean
   */
  const isValidForm = () => {
    let isValid = false;
    if (formData && formData.name &&
      formData.email &&
      formData.query) {
      isValid = true;
    }
    return isValid;
  }

  /**
   * On submit the form
   */
  const onSubmit = () => {
    if (isValidForm()) {
      // Submitting data to backend for registration
      setIsLoading(true);

      const formPostData = {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        query: formData.query
      }
      
      postQuery(formPostData).then((data) => {
        if (data.success) {
          setFormData(null);
          handleClose();
          showSnackbar().success(data.message)
        } else {
          showSnackbar().failure(data.message)

        }
      }).finally(() => setIsLoading(false));
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className='contact-btn' variant="outlined" onClick={handleClickOpen}>
        Get in touch
      </Button>

      <span className="whstapp-btn" onClick={handleClickOpen} style={{ display: 'none' }} ><img src={mail} alt="Inquire" /><p style={{ margin: '0' }}>Inquire</p></span>

      <Dialog id="contact-modal" open={open} onClose={handleClose}>
        <DialogContent>
          <DialogActions className='float-btn'>
            <span onClick={handleClose}><svg width="25" height="25" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.53341 25.8334L6.66675 23.9667L14.1334 16.5001L6.66675 9.03341L8.53341 7.16675L16.0001 14.6334L23.4667 7.16675L25.3334 9.03341L17.8667 16.5001L25.3334 23.9667L23.4667 25.8334L16.0001 18.3667L8.53341 25.8334Z" fill="#FFFFFF" />
            </svg>
            </span>
          </DialogActions>
          <div className='contct-grid-wrp f-wrp'>
            <div className='img-blk f-wrp'>
              <span><img src={AppIcons.ContactImg} alt="building night view" /></span>
            </div>
            <div className='contct-form-wrp f-wrp'>
              <DialogTitle>Contact us</DialogTitle>

              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e)=>setFormValue('name', e.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                id="phone"
                name="phoneNumber"
                label="Phone"
                type="number"
                fullWidth
                variant="standard"
                onChange={(e)=>setFormValue('phoneNumber', e.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                id="email"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                onChange={(e)=>setFormValue('email', e.target.value)}
              />
              <TextField
                id="Message"
                name="query"
                label="Message"
                multiline
                rows={4}
                fullWidth
                defaultValue=""
                variant="standard"
                onChange={(e)=>setFormValue('query', e.target.value)}
              />

              <Button disabled={!isValidForm()} onClick={onSubmit}>{isLoading ? 'Submitting...' :'Submit'}</Button>

            </div>
          </div>

        </DialogContent>

      </Dialog>
    </div>
  );
}

export default ContactPopup