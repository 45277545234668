import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const SamplePage = (props) => {
  useEffect(() => { window.open('https://wa.me/971508880091', '_blank'); });

  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => { navigate('/'); }, 10);
    return () => clearTimeout(timeout);
  },);
  return (
    <>
    </>
  )
}

export default SamplePage;