import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./sliderThumb.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

export default function SliderThumbPage({images = [], thumbnails = []}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const sliderImages = images.filter((img)=> {
    const isThumbnail = thumbnails.find((i)=>i.fileKey === img.fileKey);
    return isThumbnail !== undefined;
});
const sliderImagesWithThumbnails = thumbnails.concat(sliderImages);

  

  return (
    <>
      <div className="row">
        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: '0', paddingRight: '0' }}>
          <Swiper
            style={{
              "--swiper-navigation-color": "#C6C6C6",
              "--swiper-pagination-color": "#C6C6C6",
            }}
            loop={true}
            spaceBetween={25}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {sliderImagesWithThumbnails.map((element, key) => (
              <SwiperSlide key={key}>
                <img src={element} alt="property" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12" style={{ paddingRight: '0', paddingLeft: '0', }}>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            direction="vertical"
            spaceBetween={7}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {sliderImages.map((img, key) => (
              <SwiperSlide key={key}>
                <img src={img} alt="property" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

    </>
  );
}
