import React from "react";
import AppIcons from "../../assets/images/icons";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import './TrustedPartners.scss'



import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Autoplay } from "swiper";





const partnersContent = [
  {
    icon: AppIcons.partner1,
    iconClass: 'partner1',
  },
  {
    icon: AppIcons.partner2,
    iconClass: 'partner2',
  },
  {
    icon: AppIcons.partner3,
    iconClass: 'partner3',
  },
  {
    icon: AppIcons.partner4,
    iconClass: 'partner4',
  },
  {
    icon: AppIcons.partner5,
    iconClass: 'partner5',
  },
  {
    icon: AppIcons.partner1,
    iconClass: 'partner1',
  },
  {
    icon: AppIcons.partner2,
    iconClass: 'partner2',
  },
  {
    icon: AppIcons.partner3,
    iconClass: 'partner3',
  },
  {
    icon: AppIcons.partner4,
    iconClass: 'partner4',
  },
  {
    icon: AppIcons.partner5,
    iconClass: 'partner5',
  },
];

// const settings = {
//   dots: false,
//   arrows: false,
//   infinite: true,
//   speed: 1000,
//   slidesToShow: 4,
//   // slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 1500,
//   zoom: true,
//   pauseOnHover: false,
//   variableWidth: true,

//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//       }
//     },
//     {
//       breakpoint: 991,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//       }
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       }
//     },
//   ]
// };


function TrustedPartners() {
  // const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // const progressCircle = useRef(null);
  // const progressContent = useRef(null);
  // const onAutoplayTimeLeft = (s, time, progress) => {
  //   progressCircle.current.style.setProperty('--progress', 1 - progress);
  //   progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  // };

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 3500,
  //   slidesToShow: 4,
  //   // slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2500,
  //   zoom: true,
  //   pauseOnHover: false,
  //   // variableWidth: true,

  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       }
  //     },
  //   ]
  // };
  return (
    <>
      <Swiper
        // spaceBetween={5}
        centeredSlides={true}
        slidesPerView={1}
        speed={1500}
        loop
        autoplay={{
          delay: 700,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          500: {
            slidesPerView: 1,
            spaceBetween: 0,
            speed:1000,
            autoplay:{
              delay: 800,
            },
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
            speed:1000,
            autoplay:{
              delay: 800,
            },
          },
          800: {
            slidesPerView: 2,
            spaceBetween: 0,
            speed:1000,
            autoplay:{
              delay: 800,
            },
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="Swiperpartner-slide"
      >
        {partnersContent.map((element, key) => (
          <SwiperSlide className="partner-slide">
            <div className="each-partner-sec" key={key}>
              {element.icon && <span className={element.iconClass}><img src={element.icon} alt="developer-icon" /></span>}
            </div>
          </SwiperSlide>
        ))}


      </Swiper>
    </>
  );
}

export default TrustedPartners;