import instance from './axios';

const axiosInstance = instance;

/**
 * Gets the list of properties
 * @returns Object
 */
export const getProperties = async () => {
    try {
        const result = await axiosInstance.get('/properties?limit=100&page=1');
        const data = result.data?.results.filter((prop)=>prop.status === "active") || [];        
        return data;
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * Gets the list of properties
* PropertyId string
 * @returns Object
 */
export const getProperty = async (propertyId) => {
    try {
        const result = await axiosInstance.get(`/properties/${propertyId}`);
        const data = result.data || null;        
        return data;
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}