import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppIcons from '../../../assets/images/icons';
import BackArrow from '../../../assets/images/icons/BackArrow.svg';
import MapPinBlk from '../../../assets/images/icons/MapPinBlk.svg';
import ArrowsOutBlk from '../../../assets/images/icons/ArrowsOutBlk.svg';
import focusBlk from '../../../assets/images/icons/focusBlk.svg';
// import discountBlk from '../../../assets/images/icons/discountBlk.svg';
// import whatsappIcon from '../../../assets/images/icons/whatsappIcon.svg';

import whatsap from '../../../assets/images/icons/whatsap.svg';
// import phone from '../../../assets/images/icons/call.svg';
// import mail from '../../../assets/images/icons/email.svg';


import "./listing.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import CallPopup from "../../../Components/ContactPopup/CallPopup";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import { getProperties } from "../../../services/api/property";
import { showSnackbar } from '../../../Components/Notification/Snackbar';
import RichTextViewer from "../../../Components/RichText/RichTextViewer";
import ListThumbSlider from "../../../Components/ListThumbSlider";

/**
 * @description - this is a listing page for all properties. It uses react-router
* to navigate between pages and also has some basic routing logic in place
 */
const Listing = ({ history }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  ScrollToTopOnMount();

  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    getPropertiesAs()
    return () => {
      setData([])
    };
  }, []);

  // get users from api
  const getPropertiesAs = () => {
    getProperties()
      .then((data) => setData(data))
      .catch(() => showSnackbar().failure('Something went wrong'))
      .finally(() => setIsLoading(false))

  }


  return (
    <div id='app-main-content' className="main-content-wrapper listing-wrapper f-wrp">
      <div className='main-banner-wrp inner-banner f-wrp'>
        <div className='banner-bg f-wrp'>
          <img src={AppIcons.banner} alt='buildings' />
        </div>
      </div>
      <div className="breadcrumb f-wrp">
        <div className="container">
          <Link to={"/"}><img src={BackArrow} alt="back-arrow" />Home</Link>
        </div>
      </div>
      <div className="list-main-wrapper f-wrp">
        <div className="container">

          <div className='value-header f-wrp'>
            <h1>All Listings</h1>
          </div>

          <div className="list-con-sec f-wrp">
            <span style={{ textAlign: 'center', display: 'block' }}>{(isLoading) ? "Loading properties" : ''}</span>
            <span style={{ textAlign: 'center', display: 'block' }}>{(!isLoading && data.length === 0) ? "No Results found" : ''}</span>

            {data.map((element, key) => (
              <>
                <div className="each-list-blk f-wrp" key={key}>
                  <Link className="absolute-btn" to={`/property-detail/${element.id}`} ></Link>
                  <div className="img-gallery-wrp f-wrp">
                    {(element.images.length > 0) && <ListThumbSlider images={element.images.filter((i)=>i.tempURL).map((img) => img.tempURL)} thumbnails={element.thumbnails.filter((i)=>i && i.tempURL).map((img) => img?.tempURL)} />}
                  </div>
                  <div className="list-cont-sec f-wrp">
                    <div className="prop-detail-wrp f-wrp">
                      <label className="prop-label"><span>{element.title}</span> <b>AED {element.price}</b></label>
                      <div className="mob-price">{element.location && <span className="location"><img src={MapPinBlk} alt="map-pin" />{element.location}</span>}
                        <b><i>AED</i> {element.price}</b></div>
                      <ul className="flex-list f-wrp">
                        {element.plot &&
                          <li>
                            <span className="list-icon"><img src={ArrowsOutBlk} alt="arrowout-icon" /></span>
                            <p>Plot: <b>{element.plot}</b></p>
                          </li>}
                        {element.bua &&
                          <li>
                            <span className="list-icon"><img src={focusBlk} alt="focus-icon" /></span>
                            <p>BUA: <b>{element.bua}</b></p>
                          </li>}
                        {/* {element.commision &&
                        <li>
                          <span className="list-icon"><img src={discountBlk} alt="discount-icon" /></span>
                          <p>Commision: <b>{element.commision}</b></p>
                        </li>} */}
                      </ul>
                      <div className={`split feature-list f-wrp`}>
                        {element.features && <>
                          <label>Features:</label>
                          <div>
                            <RichTextViewer richText={element.features} />
                          </div>
                        </>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eachlist-contact-sec f-wrp">
                  <ul>
                    <li>
                      <div className="contct-btn-sec f-wrp">
                        {/* <Link className="whstapp-btn" to={"/"} ><img src={phone} alt="call" />Call</Link> */}
                        <CallPopup />
                      </div>
                    </li>
                    <li>
                      <div className="contct-btn-sec f-wrp">
                        {/* <Link className="whstapp-btn" to={"/"} ><img src={whatsap} alt="whatsapp" /><span>WhatsApp</span></Link> */}
                        <div className="whstapp-btn" onClick={() => window.open('https://wa.me/971508880091', '_blank')}><img src={whatsap} alt="whatsapp" /><span>WhatsApp</span></div>
                      </div>
                    </li>
                    <li>
                      <div className="contct-btn-sec inquiry f-wrp">
                        <ContactPopup />
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
