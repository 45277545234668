export const PropertyModel = {
    id: '',
    title: '',
    subTitle: '',
    description: '',
    price: '',
    address: '',
    referent: '',
    features: '',
    currency: '',
    plot: '',
    bua: '',
    location: '',
    images: [],
    agentName: '',
}