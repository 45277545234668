import instance from './axios';

const axiosInstance = instance;

/**
 * Post a postQuery
 * @param {Object} postData email and password
 */
export const postQuery = async (postData) => {
    try {
        const result = await axiosInstance.post('/user-query', postData);
        const {status} = result;        
        return ({ success: status===201 , message: "Your message submitted successfully!"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}
