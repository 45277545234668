import React, { useState, useEffect } from 'react';
import DrawerAppBar from '../BurgerMenu/DrawerAppBar';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const location = useLocation();
  const [currentURL, setCurrentURL] = useState('')
  const urlSeparator = currentURL.split('/')[1];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20)

    })
    setCurrentURL(location.pathname); // to set the current url
  }, [location.pathname])




  return (
    <div className={`${scroll ? "main-header scrolled" : "main-header"} project-main-header ${currentURL === '/' ? 'inside-home-wrp' : 'outside-home-wrp'} ${urlSeparator === 'fractional' ? 'inside-fractional-wrp' : null} ${urlSeparator === 'traditional' ? 'inside-traditional-wrp' : null} f-wrp`}>
      <div className="container">
        <DrawerAppBar />
      </div>
    </div>
  )
}

export default Header;