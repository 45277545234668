import { React, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppIcons from '../../../assets/images/icons';
import BackArrow from '../../../assets/images/icons/BackArrow.svg';
import ArrowsOutBlk from '../../../assets/images/icons/ArrowsOutBlk.svg';
import focusBlk from '../../../assets/images/icons/focusBlk.svg';
// import discountBlk from '../../../assets/images/icons/discountBlk.svg';
import whatsappIcon from '../../../assets/images/icons/whatsappIcon.svg';

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";

import "./listing.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import SliderThumbPage from "./SliderThumbPage";
import { showSnackbar } from "../../../Components/Notification/Snackbar";
import { getProperty } from "../../../services/api/property";
import { PropertyModel } from "../../../models/property.model";
import RichTextViewer from "../../../Components/RichText/RichTextViewer";



const PropertyDetails = () => {

  ScrollToTopOnMount();
  let params = useParams();
  const propertyId = params?.id || '';
  const [property, setProperty] = useState(PropertyModel);

  ScrollToTopOnMount();

  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    if (propertyId) {
      getPropertyAs()
    }
    return () => {
      setProperty(null)
    };
    // eslint-disable-next-line
  }, []);

  // get users from api
  const getPropertyAs = () => {
    getProperty(propertyId).then((data) => setProperty(data)).catch(() => showSnackbar().failure('Something went wrong'))

  }



  return (
    <div id='app-main-content' className="main-content-wrapper listing-wrapper listing-detail-wrp f-wrp">
      <div className="container">
        <div className="breadcrumb f-wrp">
          <Link to={"/exclusive-listings"}><img src={BackArrow} alt="back-arrow" />All listings</Link>
          <h1 className="prop-label">{property?.title}</h1>
        </div>

        <div className="f-wrp sdadfds">
        </div>
        <div className="detail-slider-wrp f-wrp">
          {/* <Carousel
            className="carousel-flex"
            axis="horizontal"
            verticalSwipe="natural"
            autoFocus
            autoPlay
            centerMode
            Thumb
            centerSlidePercentage={100}
            emulateTouch
            infiniteLoop
            thumbWidth={200}
            showThumbs={true}

          >
            <div>
              <img src={AppIcons.prop1_01} alt="property" />
            </div>
            <div>
              <img src={AppIcons.prop1_02} alt="property" />
            </div>
            <div>
              <img src={AppIcons.prop1_03} alt="property" />
            </div>

          </Carousel> */}

          <SliderThumbPage images={property?.images?property?.images.map(img=>img.tempURL): []}
          thumbnails={property?.thumbnails?property?.thumbnails.filter((i)=>i && i.tempURL).map(img=>img.tempURL): []} />
        </div>
        <br></br>
        <div className="property-detils each-block-sec f-wrp">
          <div className="prop-detail-wrp f-wrp">
            <div className="mob-price"><b><i>AED</i> {property?.price}</b></div>
            <ul className="flex-list f-wrp">
              <li>
                <span className="list-icon"><img src={ArrowsOutBlk} alt="arrowout-icon" /></span>
                <p>Plot: <b>{property?.plot} sft</b></p>
              </li>
              <li>
                <span className="list-icon"><img src={focusBlk} alt="focus-icon" /></span>
                <p>BUA: <b>{property?.bua} sft</b></p>
              </li>
              {/* <li>
                <span className="list-icon"><img src={discountBlk} alt="discount-icon" /></span>
                <p>Commision: <b>2%</b></p>
              </li> */}
            </ul>
          </div>
          <div className="agent-detail-wrp f-wrp">
            <ul>
              <li>
                <div className="profile-blk">
                  {/* <h4>{property?.agentName}</h4> */}
                  <h4>Agent</h4>
                  <span><img src={AppIcons.agentDefault} alt="agent" /></span>
                </div>
              </li>
              <li>
                <div className="contct-btn-sec f-wrp">
                  <span className="whstapp-btn" onClick={() => window.open('https://wa.me/971508880091', '_blank')} ><img src={whatsappIcon} alt="whatsapp" />Contact agent</span>
                  {/* <Link className="whstapp-btn" to={"/"} ><img src={whatsappIcon} alt="whatsapp" />Contact agent</Link> */}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="property-description-blk feature-list each-block-sec f-wrp">
          <h4>Description:</h4>
          <p>{property?.description}</p>
        </div>

        <div className="property-feature-blk feature-list each-block-sec f-wrp">
          <h4>Features:</h4>
          {(property?.features) && <RichTextViewer richText={property?.features} />}
        </div>
        <div className="property-location-blk feature-list each-block-sec f-wrp">
          <h4>Location:</h4>
          <span>{property?.location}</span>
          <br></br>
          <br></br>
          <div className="map-wrp">
            <img src={AppIcons.map} alt="map" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
