import React from "react";
import AppIcons from "../../../assets/images/icons";
// import TeamLogo from "../../../assets/images/icons/teamLogo.svg";

import "./about.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";
import OurValues from "../../../Components/OurValues/OurValues";


const AboutUs = () => {
  ScrollToTopOnMount();

  const TeamList = [
    {
      Name: 'Sarah Sajwani',
      Position: 'CEO',
      About: 'Sarah Sajwani is an Emirati tech savvy entrepreneur holding degrees in Architecture, EMBA and a Guinness World Record certificate. She managed transformative ventures during her career as a project manager and founded a few startups. Sarah is a great team builder/leader, a great problem solver and has a global vision for Propshop’s growth. Sarah is an advocate for female empowerment and is a firm believer that combining her skills with her international network of leaders will shed the lights on Propshop Real Estate.',
      Image: AppIcons.team1,
    },
    // {
    //   Name: 'Sara Adra',
    //   Position: 'CMO',
    //   About: 'Sara is a dynamic business leader with expertise in operations, marketing and team leadership. She excels in identifying growth opportunities, optimizing processes and implementing marketing initiatives for profitability aand customer engagement. With a strong track record in stakeholder management, Sara has joined Propshop to drive branding excellence and create meaningful client expertise.',
    //   Image: AppIcons.team2,
    // },
    {
      Name: 'Fadhil Sajwani',
      Position: 'Real Estate Agent',
      About: <>Fadhil Sajwani is an Emirati business and sales coach, well-connected and highly skilled with 15+ years of experience in wealth management, ranging from structuring and advancing of property and financial solutions for corporate and private clients. <br/><br/>Fadhil is a savvy negotiator and he has a Bachelor of Business Administration in Finance and Banking of University of Dubai. </>,
      Image: AppIcons.team7,
    },
    {
      Name: 'Abdullah Sajwani',
      Position: 'PRO',
      About: 'Abdullah Sajwani is an undergraduate student studying Aeronautical Engineering with a keen interest in the real estate industry. Combining my passion for aviation and my knowledge of real estate, I bring a unique perspective to my studies. With a strong STEM foundation and a drive to excel, I aim to contribute to the future of both industries. Excited by the possibilities of innovation and growth in real estate, I aspire to make a meaningful impact in this dynamic field.',
      Image: AppIcons.team8,
    },
    // {
    //   Name: 'TAMARA ALÉ',
    //   Position: 'Real Estate Broker',
    //   About: 'Tamara Alé is a Senior Real Estate agent with a master degree in International Law. She has 5+ years of real estate experience in USA and UAE. She is an expert in building strong long-term connections with clients, expanding her network of investors and new homeowners. Tamara joined Propshop because her vision is aligned with the company’s; complete transparency and delivering a top-notched client focused experience.',
    //   Image: AppIcons.team5,
    // },
    // {
    //   Name: 'Vicky Waqas',
    //   Position: 'Finance Manager',
    //   About: 'With 8+ years of financial management, business analysis and project management experience, Vicky has led successful organizational restructures to increase efficiency and profitability. Notable highlights of his career include restructuring and implementing 4 high-turnover F&B projects on time and budget across the Middle East and the UK, growing annual turnover and profits and optimizing liquidity of both organizations.',
    //   Image: AppIcons.team4,
    // },
    {
      Name: 'Nina Navoa',
      Position: 'Administrative Assistant',
      About: 'Nina Navoa is an Administrative Assistant reporting to the CEO, with Real Estate experience in Middle East and the US. She is highly detail oriented and makes our life easier.',
      Image: AppIcons.team3,
    },
    {
      Name: 'SANTIAGO CARNICERO',
      Position: 'Business and Tech Advisor',
      About: 'Highly experienced and results-driven professional with 10 years of expertise in digital transformation and business management consulting. His unique blend of technical knowledge and strategic thinking allows him to identify opportunities for innovation, crafting comprehensive digital strategies that optimize efficiency, productivity, and profitability. He has successfully guided numerous companies through their digital transformation.',
      Image: AppIcons.team6,
    },
  ];

  return (
    <div className="page-main-wrapper about-main-wrapper f-wrp">
      <div className="container">
        <div className="abt-img-wrp f-wrp">
          <span><img src={AppIcons.AbtImg} alt="propshop-logo" /></span>
        </div>
        <div className="abt-cont-wrp f-wrp">
          <h1 className="abt-main-header">Discover the epitome of luxury living with Propshop Real Estate's PREMIUM properties</h1>
          <label>PAYMENT OPTIONS</label>
          <p>Facilitating and accepting all forms of payment (credit, card ,bank ,transfers cash and crypto).</p>
          <br></br>
          <div className='value-main-wrpp our-value-wrp f-wrp'>

            <div className='value-header f-wrp'>
              <label>Our Values</label>
            </div>
            <OurValues />

          </div>
          <br></br>
          <label>OUR TEAM</label>
          <p>A team well equipped in building generational wealth. </p>
        </div>
        <div className="team-wrp-sec f-wrp">
          <div className="team-detail-wrp f-wrp">
            {TeamList.map((element, key) => (
              <div className="each-team-sec f-wrp" key={key}>
                <div className="team-detail-sec f-wrp">
                  <label> {element.Name}<b>{element.Position}</b></label>
                  <p> {element.About}</p>
                </div>
                <div className="team-img-sec f-wrp">
                  <span><img src={element.Image} alt="team members" /></span>
                </div>
              </div>
            ))}
          </div>
        </div>


        <br></br>
        <div className="abt-cont-wrp f-wrp">
          <label>QUESTIONS?</label>
          <div className="contact-block-wrp f-wrp">
            <ContactPopup />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
