import React from "react";
import AppIcons from "../../assets/images/icons";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Autoplay } from "swiper";



import './TopDevelopers.scss'


const topdevelopers = [
  {
    icon: AppIcons.developer1,
    iconClass: 'developer1',
  },
  {
    icon: AppIcons.developer2,
    iconClass: 'developer2',
  },
  {
    icon: AppIcons.developer3,
    iconClass: 'developer3',
  },
  {
    icon: AppIcons.developer4,
    iconClass: 'developer4',
  },
  {
    icon: AppIcons.developer5,
    iconClass: 'developer5',
  },
  {
    icon: AppIcons.developer6,
    iconClass: 'developer6',
  },
  {
    icon: AppIcons.developer1,
    iconClass: 'developer1',
  },
  {
    icon: AppIcons.developer2,
    iconClass: 'developer2',
  },
  {
    icon: AppIcons.developer3,
    iconClass: 'developer3',
  },
  {
    icon: AppIcons.developer4,
    iconClass: 'developer4',
  },
  {
    icon: AppIcons.developer5,
    iconClass: 'developer5',
  },
  {
    icon: AppIcons.developer6,
    iconClass: 'developer6',
  },
];

// const settings = {
//   dots: false,
//   arrows: false,
//   infinite: true,
//   speed: 3500,
//   slidesToShow: 4,
//   // slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2500,
//   zoom: true,
//   pauseOnHover: false,  
//   // variableWidth: true,

//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//       }
//     },
//     {
//       breakpoint: 991,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//       }
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       }
//     },
//   ]
// };


function TopDevelopers() {

  return (
    <>
      <Swiper
        // spaceBetween={5}
        centeredSlides={true}
        slidesPerView={1}
        speed={1600}
        loop
        autoplay={{
          delay: 800,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          500: {
            slidesPerView: 1,
            spaceBetween: 0,
            speed:1000,
            autoplay:{
              delay: 800,
            },
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
            speed:1000,
            autoplay:{
              delay: 800,
            },
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
            speed:1000,
            autoplay:{
              delay: 800,
            },
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="Swiperpartner-slide"
      >
        {topdevelopers.map((element, key) => (
          <SwiperSlide className="partner-slide top-developers" key={key}>
            <div className="each-partner-sec">
              {element.icon && <span className={element.iconClass}><img src={element.icon} alt="developer-icon" /></span>}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <Slider className="partner-slide top-developers" {...settings}>
        {topdevelopers.map((element, key) => (
          <div className="each-partner-sec" key={key}>
            {element.icon && <span className={element.iconClass}><img src={element.icon} alt="developer-icon" /></span>}
          </div>
        ))}
      </Slider> */}
    </>
  );
}

export default TopDevelopers;