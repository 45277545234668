import React, { useState, useEffect, useRef } from 'react';
// import Marquee from "react-fast-marquee";
import AppIcons from '../../assets/images/icons';
// import { Link } from '@mui/material';
import { Link } from "react-router-dom";
import { ScrollToTopOnMount } from '../ScrollToTop/ScrollToTop';
import CountdownTimer from "react-component-countdown-timer";
import './home.scss';




const Home = () => {

    const [windowSize, setWindowSize] = useState(window.innerHeight);
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            setHeight(ref.current.clientHeight)
        }, 100);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerHeight);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    //to get date values
    var startDate = new Date();
    var endDate = new Date('2024-03-12T09:00:00');
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

    var settings = {
        count: seconds,
        border: true,
        showTitle: true,
        noPoints: true,
        dayTitle: 'days',
        hourTitle: 'hours',
        minuteTitle: 'mins',
        secondTitle: 'secs',
    };


    ScrollToTopOnMount();
    return (
        <div id='app-main-content' className="main-content-wrapper f-wrp">
            <div className='main-banner-wrp hero-main-wrp f-wrp' style={{ minHeight: `${windowSize}px` }}>
                <div className='banner-bg f-wrp'>
                    <img src={AppIcons.home} alt='buildings' />
                </div>
                <div className='banner-main-content f-wrp'>

                    <div className='container'>
                        <div className='contnt-wrp f-wrp' style={{ minHeight: `${windowSize - height}px` }}>
                            <div className='banner-counter-wrp'>


                                <div className='banner-counter'>
                                    <div className='counter-content'>
                                        <h3 style={{ marginTop: '0', marginBottom: '0px' }}>Token pre-sale ends</h3>
                                        {/* <p>March 6th at 12 pm</p> */}
                                    </div>
                                    <CountdownTimer {...settings} />
                                </div>


                                <div className='banner-content'>
                                    {/* <h3 style={{ marginTop: '0', marginBottom: '0px' }}>Ascend the new<br />heights of real estate</h3> */}
                                    <h3 style={{ marginTop: '0', marginBottom: '0px' }}>The new generation of real estate investments - tokenized ownership</h3>
                                    <p>become a real estate investor for AED 500 with Propshop - the platform where fractional ownership meets blockchain technology</p>
                                    {/* <p>with fractional ownership & blockchain technology</p> */}
                                    <Link to={'/'} onClick={() => window.open('https://propshop.involve.me/fractionalownership', '_blank')} className='banner-btn' >Start my journey</Link>
                                </div>


                            </div>
                            {/* <Link to={'/exclusive-listings'} className='banner-btn' >View Exclusive Listings</Link> */}
                        </div>
                        <div className='theme-switcher f-wrp' ref={ref}>
                            <Link to={'/traditional'} className='plan-btn traditional' >
                                <p>Traditional</p>
                                <span><img src={AppIcons.chevronLeft} alt='chevron Left' /></span>
                            </Link>
                            <Link to={'/fractional'} className='plan-btn fractional' >
                                <p>Fractional</p>
                                <span style={{ float: 'right' }}><img src={AppIcons.chevronRight} alt='chevron Right' /></span>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* <div className='hero-scrolling-sec f-wrp'>

                    <Marquee
                        autoFill
                        direction={"left"}
                        speed={70}
                    >
                        <div style={{ padding: '0 75px' }}>Step into the future of fractional ownership atop the clouds, where innovation meets luxury.</div>
                    </Marquee>
                </div> */}


            </div>
        </div>
    )
}

export default Home;