import Footer from "./Components/Footer";
import Header from "./Components/Header";

function Layout (props) {
    const isLoggedIn = true;
    return (
      <div>
        {(isLoggedIn) && <Header properties={props}/>}
        <div className="content">
          {props.children}
        </div>
        {(isLoggedIn) && <Footer/>}
      </div>
    );
  }

  export default Layout;