// import React from "react";
import { React, useEffect, useState } from "react";
// import AppIcons from "../../assets/images/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './ListCarousel.scss'
// import { Link } from "@mui/material";
import { Link } from "react-router-dom";

import Mappin from './../../assets/images/icons/MapPin.svg';
import ArrowsOut from './../../assets/images/icons/ArrowsOut.svg';


import { getProperties } from "../../services/api/property";
import { showSnackbar } from '../../Components/Notification/Snackbar';



















const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  zoom: true,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};


function ListCarousel() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    getPropertiesAs()
    return () => {
      setData([])
    };
  }, []);

  // get users from api
  const getPropertiesAs = () => {
    getProperties()
      .then((data) => setData(data))
      .catch(() => showSnackbar().failure('Something went wrong'))
      .finally(() => setIsLoading(false))

  }

  return (
    <>
      <span style={{textAlign: 'center', display: 'block'}}>{(isLoading) ? "Loading properties" : ''}</span>
      <span style={{textAlign: 'center', display: 'block'}}>{(!isLoading && data.length === 0) ? "No Results found" : ''}</span>
      <Slider className="PC-slide" {...settings}>
        {data.map((element, key) => (
          <div className="each-list-items f-wrp" key={key}>
            <div className="clck-wrp"><Link to={`/property-detail/${element.id}`}></Link></div>
            <div className="list-item-bg f-wrp">
              <img src={(element.thumbnails.length > 0 && element.thumbnails[0]) ? element.thumbnails[0].tempURL : ''} alt="buildings" />
            </div>
            <div className="list-item-blk f-wrp">
              <div><label>Hot offer</label></div>
              <div className="view-btn">{element.url}</div>
              <ul className="detail-list">
                <li><span><img src={Mappin} alt="map-pin" />{element.location}</span></li>
                <li><span><img src={ArrowsOut} alt="arrow-out" />{element.price}</span></li>
              </ul>
            </div>
          </div>
        ))}
      </Slider>

      <div className="mob-list-wrp f-wrp">
        {data.map((element, key) => (
          <div className="each-list-items f-wrp">
            <div className="clck-wrp"><Link to={`/property-detail/${element.id}`}></Link></div>
            <div className="list-item-bg f-wrp">
            <img src={(element.thumbnails.length > 0 && element.thumbnails[0]) ? element.thumbnails[0].tempURL : ''} alt="buildings" />
            </div>
            <div className="list-item-blk f-wrp">
            <div><label>Hot offer</label></div>
              <div className="view-btn">{element.url}</div>
              <div>
                <h5 style={{ color: '#fff', marginBottom: '0' }}>{element.title}</h5>
                <ul className="detail-list">
                  <li><span><img src={Mappin} alt="map-pin" />{element.location}</span></li>
                  <li><span><img src={ArrowsOut} alt="arrow-out" />{element.price}</span></li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ListCarousel;