import React from "react";
import AppIcons from "../../../assets/images/icons";

import "./match.scss"
import { ScrollToTopOnMount } from "../../../Components/ScrollToTop/ScrollToTop";
import ContactPopup from "../../../Components/ContactPopup/ContactPopup";



const MatchMaking = () => {
  ScrollToTopOnMount();

  const HowItWorks = [
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      num: '01',
      title: 'Client \n Consultation',
      discription: 'We gather the information provided by the client about their desired property and its specific requirements.'
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      num: '02',
      title: 'Database \n Analysis',
      discription: "Our database is analyzed to identify properties that match the client's criteria."
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      num: '03',
      title: 'Matching \n Algorithm',
      discription: 'Our algorithms will consider the various requirements to generate a list of properties that closely match your preferences.'
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      num: '04',
      title: 'Property \n Proposal',
      discription: 'Based on the analysis and matching algorithm, we present you a curated list of properties. This list typically includes details and photos, enabling a better selection of the ones you are interested in.'
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      num: '05',
      title: 'Property \n Viewing',
      discription: 'Once properties are selected, we arrange property viewings, allowing you to physically visit the properties and assess their suitability.'
    },
    {
      className: 'col-lg-4 col-md-6 col-sm-6 col-xs-12',
      num: '06',
      title: 'Transaction \n Assistance',
      discription: 'If the client decides to proceed, we facilitate the negotiation and transaction process, assisting with paperwork, contracts, and other steps until the deal is  completed.'
    },

  ];


  return (
    <div className="page-main-wrapper match-making f-wrp">
      <div className='main-banner-wrp inner-banner f-wrp'>
        <div className='banner-bg f-wrp'>
          <img src={AppIcons.matchMaking} alt='buildings' />
        </div>
      </div>
      <div className="makeit-intro f-wrp">
        <div className="container">
          <div className="makeit-intro-con f-wrp">
            <h1>Real estate match-making</h1>
            <p>Real estate matchmaking, also known as property matching, refers to the process of connecting potential buyers or renters with suitable sellerts or properties based on their preferences and requirements.</p>
          </div>
        </div>
      </div>
      <div className="wrk-blk-sec f-wrp">
        <div className="container">
          <h1>How it works</h1>
          <div className="wrk-blk-con f-wrp">
            <div className="row">
              {HowItWorks.map((element, key) => (
                <div className={`${element.className} each-blk-sec`} key={key}>
                  <div className="each-othrServ-blk f-wrp" key={key}>
                    <span>{element.num}</span>
                    <span>{element.title}</span>
                    <p>{element.discription}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="contact-block-wrp f-wrp">
        <ContactPopup />
      </div>
    </div>
  );
};

export default MatchMaking;
