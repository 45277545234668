import React from "react";
import AppIcons from "../../assets/images/icons";

// import { Link } from "react-router-dom";
import './Services.scss';
import ContactPopup from "../ContactPopup/ContactPopup";


function OtherServices() {
  const otherServiceContent = [
    {
      // num: '01',
      title: 'Construction costs',
      // discription: 'We calculate all expenses regarding building projects.'
    },
    {
      // num: '02',
      title: 'Architectural layouts',
      // discription: 'We devise creative and  functional layouts.'
    },
    {
      // num: '03',
      title: 'Feasibility studies',
      // discription: 'We analyze the viability and potential success of projects.'
    },
    {
      // num: '04',
      title: 'Mortgage Assistance',
      // discription: 'Helping homeowners manage their mortgage payments'
    },
  ]


  return (
    <>
      <div className='othr-service-sec-blk f-wrp'>
        <div className='row'>
          <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
            <div className="othr-blk-intro f-wrp">
              {/* <h1>Providing The Best Services For You</h1> */}
              <div className="for-dev-box f-wrp">
                <h3 style={{marginBottom: '0'}}>FOR DEVELOPERS</h3>
                {/* <p>We collaborate with builders and work with government solutions to create investment opportunities for you.</p> */}
                {/* <Link to={'/'} className='banner-btn'>Get in touch</Link> */}
                <ContactPopup/>
              </div>
            </div>

          </div>
          <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
            <div className="othrServ-blk f-wrp">
              {otherServiceContent.map((element, key) => (
                <>
                  <div className="each-othrServ-blk f-wrp" key={key}>
                    {/* <span>{element.num}</span> */}
                    <span>{element.title}</span>
                   {element.discription && <p>{element.discription}</p>} 
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <div className="for-investo-blk f-wrp">
              <span className="investo-bg"><img src={AppIcons.forInvesters} alt="for investors" /></span>
              <div className="for-invest-blk">
                <h2>FOR INVESTORS</h2>
                {/* <p>Empowering investors: comprehensive services for growth, profit, and success.</p> */}
                {/* <Link to={'/'} className='banner-btn'>Get in touch</Link> */}
                <ContactPopup/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default OtherServices;