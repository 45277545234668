import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import phone from '../../assets/images/icons/call.svg';

import './ContactPopup.scss'
// import { Link } from 'react-router-dom';


function CallPopup(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span onClick={handleClickOpen} className="whstapp-btn" style={{ cursor: 'pointer' }}><img src={phone} alt="call" /><p style={{margin: '0'}}>Call</p></span>


      <Dialog id="contact-modal" open={open} onClose={handleClose}>
        <DialogContent>
          <DialogActions className='float-btn'>
            <span onClick={handleClose}><svg width="25" height="25" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.53341 25.8334L6.66675 23.9667L14.1334 16.5001L6.66675 9.03341L8.53341 7.16675L16.0001 14.6334L23.4667 7.16675L25.3334 9.03341L17.8667 16.5001L25.3334 23.9667L23.4667 25.8334L16.0001 18.3667L8.53341 25.8334Z" fill="#FFFFFF" />
            </svg>
            </span>
          </DialogActions>
          <div className='contct-grid-wrp f-wrp'>
            <div className='contct-form-wrp f-wrp' style={{ textAlign: 'center', padding: '0' }}>
            <br></br>
              <DialogTitle>Feel free to get in touch with Us!</DialogTitle>
              <br></br>
              <DialogTitle>+97-1508880091</DialogTitle>
              <br></br>

              {/* <Button onClick={handleClose}>Submit</Button> */}

            </div>
          </div>

        </DialogContent>

      </Dialog>
    </div>
  );
}

export default CallPopup