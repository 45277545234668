import React from 'react';

import AppIcons from '../../assets/images/icons';
import MenuList from '@mui/material/MenuList';
import { MenuItem } from '@mui/material';
import { Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import whatsappBlk from '../../assets/images/icons/Whatsapp-foot.svg';



const Footer = () => {


    const url = window.location.origin;
    console.log('url', url);


    return (
        <div className="project-main-footer f-wrp">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div className="foot-logo-wrp f-wrp">
                            <div className="logo-wrp f-wrp">
                                <span><img src={AppIcons.footLogo} alt="Propshop Logo" /></span>
                                <span className='foottag'><img src={AppIcons.foottag} alt="Propshop tag-line" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                <div className="each-foot-link align-class1 f-wrp">
                                    <label className="foot-sub-head">Platform</label>
                                    <MenuList>
                                        <MenuItem><Link to={'/exclusive-listings'}>Listings</Link></MenuItem>
                                        {/* <MenuItem>Off-Plan</MenuItem> */}
                                        {/* <MenuItem><Link to={'/match-making'}>Match-making</Link></MenuItem> */}
                                        {/* <MenuItem><Link to={`${url}/#testimonials`}>Testimonials</Link></MenuItem> */}

                                        <NavHashLink smooth to={`/traditional/#testimonials`} >
                                            <MenuItem><span>Testimonials</span></MenuItem>
                                        </NavHashLink>


                                    </MenuList>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">

                                <div className="each-foot-link align-class2 f-wrp">
                                    <label className="foot-sub-head">Services</label>
                                    <MenuList>
                                        <MenuItem><a href={`${url}/fractional`}>Fractional ownership</a></MenuItem>
                                        <NavHashLink smooth to={`/traditional/#Services`} >
                                            <MenuItem><span>Interior design</span></MenuItem>
                                        </NavHashLink>
                                        <NavHashLink smooth to={`/traditional/#Services`} >
                                            <MenuItem><span>Architectural design</span></MenuItem>
                                        </NavHashLink>
                                        <NavHashLink smooth to={`/traditional/#Services`} >
                                            <MenuItem><span>Landscaping</span></MenuItem>
                                        </NavHashLink>
                                        <NavHashLink smooth to={`/traditional/#Services`} >
                                            <MenuItem><span>Fengshui</span></MenuItem>
                                        </NavHashLink>
                                        <NavHashLink smooth to={`/traditional/#OtherServices`} >
                                            <MenuItem><span>Other services</span></MenuItem>
                                        </NavHashLink>
                                    </MenuList>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">

                                <div className="each-foot-link align-class3 f-wrp">
                                    <label className="foot-sub-head">Collaborators</label>
                                    <MenuList>
                                        <NavHashLink smooth to={`/traditional/#TrustedPartners`} >
                                            <MenuItem><span>Trusted partners</span></MenuItem>
                                        </NavHashLink>
                                        <NavHashLink smooth to={`/traditional/#TopDevelopers`} >
                                            <MenuItem><span>Top developers</span></MenuItem>
                                        </NavHashLink>
                                    </MenuList>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                <div className="each-foot-link align-class4 f-wrp">
                                    <label className="foot-sub-head">Company</label>
                                    <MenuList>
                                        <MenuItem onClick={() => window.open('https://wa.me/971508880091', '_blank')}>Contact Us</MenuItem>
                                        <MenuItem><Link to={'/about-us'} >About Us</Link></MenuItem>
                                    </MenuList>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 foot-cpy-sec">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                <div className="copy-txt f-wrp">
                                    <p>© 2023 Propshop Real Estate </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="copy-txt f-wrp">
                                    <p>Al Fattan Plaza Office 508/509, Al Garhoud Airport Road, Dubai, United Arab Emirates</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 each-foot-link">
                                <div className='social-list f-wrp'>
                                    <ul>
                                        <li><span onClick={() => window.open('https://www.instagram.com/propshop.ai', '_blank')}><img src={AppIcons.insta} alt="instagram" /></span></li>
                                        <li><span onClick={() => window.open('https://www.facebook.com/profile.php?id=100090850307997', '_blank')}><img src={AppIcons.facebook} alt="facebook" /></span></li>
                                        <li><span onClick={() => window.open('https://www.linkedin.com/company/propshop-real-estate/')}><img src={AppIcons.linkedin} alt="linkedin" /></span></li>
                                        <li><span onClick={() => window.open('https://www.tiktok.com/@sarsoorah')}><img src={AppIcons.ticktok} alt="ticktok" /></span></li>
                                        <li><span onClick={() => window.open('https://wa.me/971508880091', '_blank')}><img src={whatsappBlk} alt="whatsapp" /></span></li>
                                        {/* <li><span to={'/'} ><img src={AppIcons.snapchat} alt="icon" /></span></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;