import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppIcons from '../../assets/images/icons';
import NavClose from '../../assets/images/icons/navClose.svg';
import { NavHashLink } from 'react-router-hash-link';
import Home from '../../assets/images/icons/home.svg';
import Listing from '../../assets/images/icons/listing.svg';
import Aboutus from '../../assets/images/icons/about.svg';
import ContactUs from '../../assets/images/icons/contact.svg';

import Fractional from '../../assets/images/icons/Fractional.svg';
import Eligibility from '../../assets/images/icons/Eligibility.svg';
import Traditional from '../../assets/images/icons/Traditional.svg';
import Services from '../../assets/images/icons/Services.svg';

// import Login from '../../assets/images/icons/login.svg';


import './DrawerApp.css'
import { Link } from 'react-router-dom';

const drawerWidth = 350;
const navItems = [
  {
    title: 'Home',
    icon: Home,
    link: '/'
  },
  // {
  //   title: 'Services',
  //   icon: Aboutus,
  //   link: '/'
  // },
  // {
  //   title: 'Match-making',
  //   icon: Aboutus,
  //   link: '/match-making'
  // },
  {
    title: 'About',
    icon: Aboutus,
    link: '/about-us'
  },
  {
    title: 'Contact',
    icon: ContactUs,
    link: '/contact-us'
  },
];

const mobNavItems = [
  {
    title: 'Home',
    icon: Home,
    link: '/'
  },
  {
    title: 'Fractional ownership',
    icon: Fractional,
    link: '/fractional'
  },
  {
    title: 'Eligibility quiz',
    icon: Eligibility,
    // link: '/'
    customLink: 'https://propshop.involve.me/fractionalownership',
  },
  {
    title: 'Traditional ownership',
    icon: Traditional,
    link: '/traditional'
  },
  // {
  //   title: 'Services',
  //   icon: Aboutus,
  //   link: '/'
  // },
  // {
  //   title: 'Match-making',
  //   icon: Aboutus,
  //   link: '/match-making'
  // },
  {
    title: 'Listings',
    icon: Listing,
    link: '/exclusive-listings'
  },
  {
    title: 'Services',
    icon: Services,
    hashLink: '/traditional/#Services'
  },
  {
    title: 'About us',
    icon: Aboutus,
    link: '/about-us'
  },
  {
    title: 'Contact us',
    icon: ContactUs,
    link: '/contact-us',
  },
  // {
  //   title: 'Login',
  //   icon: Login,
  //   link: '/'
  // },
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className='mob-drawer'>

      <div className="close-btn f-wrp">
        <span><img src={NavClose} alt="Nav Close Icon" /></span>
      </div>

      {/* <Divider /> */}
      <List>
        {mobNavItems.map((element, key) => (
          <ListItem key={element.title} className={`${element.title}`} disablePadding>

            {element.customLink? <a href={`${element.customLink}`} target="_blank" rel="noreferrer" style={{ width: '100%' }} >
              <ListItemButton sx={{ textAlign: 'center' }}>
                <span className='menu-icon'><img src={element.icon} alt="Menu-icon" /></span>
                <ListItemText primary={element.title} />
              </ListItemButton>
            </a> : (element.hashLink? <NavHashLink smooth to={`${element.hashLink}`} >                                        
              <ListItemButton sx={{ textAlign: 'center' }}>
                <span className='menu-icon'><img src={element.icon} alt="Menu-icon" /></span>
                <ListItemText primary={element.title} />
              </ListItemButton>
              </NavHashLink> : <Link to={`${element.link}`} style={{ width: '100%' }} >
              <ListItemButton sx={{ textAlign: 'center' }}>
                <span className='menu-icon'><img src={element.icon} alt="Menu-icon" /></span>
                <ListItemText primary={element.title} />
              </ListItemButton>
            </Link>)}            
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" className="custom-navbar" >
        <Toolbar className="custom-header" style={{ justifyContent: 'space-between' }}>
        <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
          >
            <div className="logo-wrp f-wrp">
              <span><Link to="/" style={{ display: 'inline-block' }}><img className='logo-default' src={AppIcons.logo} alt="Propshop Logo" /> <img className='logo-sec' src={AppIcons.logo} alt="Propshop Logo" /></Link></span>
            </div>
          </Typography>
          <IconButton
            className='pc-icon'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className="logo-wrp f-wrp">
              <span><Link to="/" style={{ display: 'inline-block' }}><img className='logo-default' src={AppIcons.logo} alt="Propshop Logo" /> <img className='logo-sec' src={AppIcons.logo} alt="Propshop Logo" /></Link></span>
            </div>
          </IconButton>
          <IconButton
            className='mob-icon'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block' } }}
          >
            <div className="logo-wrp f-wrp">
              <span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.33337 9.33337H9.33337M26.6667 9.33337H14.6667M26.6667 22.6667H22.6667M5.33337 22.6667H17.3334M5.33337 16H26.6667" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </span>
            </div>
          </IconButton>

          
          <Box sx={{ display: { xs: 'none', sm: 'none' } }}>
            {navItems.map((element, key) => (
              <Link to={`${element.link}`} style={{ width: '100%' }} >
                <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                  {element.title}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          className='mob-draw-wrp'
          container={container}
          variant="temporary"
          open={mobileOpen}
          anchor='right'
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;