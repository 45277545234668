import { React } from "react";
import { Link } from "react-router-dom";
import AppIcons from "../../assets/images/icons";
import './fractional.scss';

import telegramChannel from '../../assets/images/icons/TelegramChannel.svg';
import whatsAppChannel from '../../assets/images/icons/whatsAppChannel.svg';

function FractionalPage() {

  return (
    <>
      <div className="fractional-main-wrp f-wrp">
        <div className="each-fractional-wrp f-wrp">
          <div className="container">

            <div className="theme-page-switch">
              <h3>How to get started</h3>
              {/* <ul>
                <li><span>Fractional</span></li>
                <li><Link to={'/traditional'} >Traditional</Link></li>
              </ul> */}
            </div>

            <div className="gap f-wrp" style={{ paddingTop: '60px' }} />

            <div className="each-fractional-sec f-wrp">

              <div className="each-fractional-box f-wrp">
                <a href="https://propshop.involve.me/fractionalownership" target="_blank" rel="noreferrer">
                  <div className="fractional-head f-wrp">
                    <span><img src={AppIcons.check} alt="sign up" /></span>
                    <h3>Get up to speed</h3>
                  </div>
                </a>
                <div className="fractional-con f-wrp">
                  <p>We will provide you with the fundamentals of tokenized ownership through an interactive quiz.</p>
                </div>
              </div>

              <div className="gap f-wrp" style={{ paddingTop: '50px' }} />

              <div className="each-fractional-box f-wrp">
                <a href="https://jourihillstoken.store/account/login?checkout_url=%2F" target="_blank" rel="noreferrer">
                  <div className="fractional-head f-wrp">
                    <span><img src={AppIcons.signUp} alt="sign up" /></span>
                    <h3>Sign up</h3>
                  </div>
                </a>

                <div className="fractional-con f-wrp">
                  <p>Create your account in minutes to unlock exclusive access to our platform and start your investment journey.</p>
                </div>
              </div>

            </div>

          </div>
          <span className="fraction-bg-img bgImg-1"><img src={AppIcons.fractionalBg1} alt="fractional bg" /></span>
        </div>
        <div className="each-fractional-wrp f-wrp">
          <div className="container">

            <div className="each-fractional-sec f-wrp">
              <div className="each-fractional-box f-wrp">
                <div className="fractional-head f-wrp">
                  <span><img src={AppIcons.choose} alt="sign up" /></span>
                  {/* <h3>Choose a property</h3> */}
                  <h3>Select number of tokens</h3>
                </div>
                <div className="fractional-con f-wrp">
                  {/* <p>Browse our curated selection of luxury properties and choose the one that aligns with your investment goals and preferences.</p> */}
                  <p>Learn more about the property by reviewing the white paper.</p>
                </div>
              </div>

              <div className="gap f-wrp" style={{ paddingTop: '50px' }} />

              <div className="each-fractional-box f-wrp">
                <div className="fractional-head f-wrp">
                  <span><img src={AppIcons.invest} alt="sign up" /></span>
                  <h3>Start investing</h3>
                </div>
                <div className="fractional-con f-wrp">
                  <p>Once you've selected your property, begin your investment journey and start building your portfolio.</p>
                </div>
              </div>
            </div>

          </div>

          <div className="eligible-btn-box f-wrp">
            <div className="container">
              <a href="https://propshop.involve.me/fractionalownership" target="_blank" rel="noreferrer" className="eligible-btn">Start my journey</a>
              <div className="social-wrp f-wrp">
                <p>Join our communities and get closely updated.</p>
                <ul>
                  <li><a href="https://chat.whatsapp.com/BqnHMWhb0J81uiGNPjGf0Y" target="_blank" rel="noreferrer" className="social-btn whatsapp"><img src={whatsAppChannel} alt="whatsapp-icon" /></a></li>
                  <li><a href="https://t.me/+A3FsMnkK5uAwOTVk" target="_blank" rel="noreferrer" className="social-btn telegram"><img src={telegramChannel} alt="telegramChannel-icon" /></a></li>
                </ul>
              </div>
            </div>
          </div>

          <span className="fraction-bg-img bgImg-2"><img src={AppIcons.fractionalBg2} alt="fractional bg" /></span>
        </div>
        <div className="theme-page-switch  f-wrp" style={{margin: '0 auto', paddingBottom: '50px'}}>
              <ul>
                <li><span>Fractional</span></li>
                <li><Link to={'/traditional'} >Traditional</Link></li>
              </ul>
            </div>
      </div>
    </>
  );
}

export default FractionalPage;